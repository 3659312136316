import {
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { buildUniqueComponentId } from 'src/app/shared/utils';

@Component({
  selector: 'spaui-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrl: './collapsible.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CollapsibleComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    id: buildUniqueComponentId('spaui-collapsible'),
  },
})
export class CollapsibleComponent {
  @Input() direction!: string;
  @Input() id!: string;
  @Input() pageDirection!: string;
  @Input() titleHeader!: string;
  @Input() titleHeaderIcon!: string;
  @Input() expanded!: boolean;
  @Input() showTextIcon: boolean = false;
  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() title!: string;
  @Input() content!: string;
  @Input() header: boolean = true;
  @Output() panelClicked: EventEmitter<CollapsibleComponent> = new EventEmitter<CollapsibleComponent>();

  togglePanel() {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
    this.panelClicked.emit(this);
  }
}
