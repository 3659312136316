<div class="collapsible-container">
  <div [ngClass]="{'collapsed': !expanded, 
'vertical': direction === 'vertical', 
'horizontal': direction === 'horizontal',
'left': pageDirection === 'left',
'right': pageDirection === 'right'}">

    <div *ngIf="header" class="collapsible-header" [ngClass]="expanded?'header-expanded':''">
      <div class="collapsible-header-text" *ngIf="expanded" [ngClass]="pageDirection === 'right'? 'right-order':''">
        <div class="collapsible-text" [ngClass]="pageDirection === 'right'? 'right-order':''">
          <img *ngIf="showTextIcon" src="{{titleHeaderIcon}}" alt="collapse"/>
          <div [ngClass]="pageDirection === 'right'? 'padding-right':''">{{titleHeader}} </div>
        </div>
        <img class="close-icon" alt="close" src="../../../../../assets/images/icons/close.svg" (click)="togglePanel()" />
      </div>
      <div class="collapsible-header-icon" *ngIf="!expanded" (click)="togglePanel()" alt="collapse">
        <img src="{{titleHeaderIcon}}" alt=""/>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>