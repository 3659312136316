import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsibleComponent } from './components/collapsible/collapsible.component';

@NgModule({
  declarations: [CollapsibleComponent],
  imports: [CommonModule],
  providers: [],
  exports: [CollapsibleComponent],
})
export class CollapsibleModule {}
